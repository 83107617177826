import { Badge, Box, Button, DrawerBody, DrawerFooter, Flex, FormControl, FormHelperText, FormLabel, Input, List, ListIcon, ListItem, Progress, Select, Spinner, Stack, Text, useToast } from "@chakra-ui/react";
import { AuthContext } from "contexts/AuthContext";
import { useUpload } from "hooks/useContent";
import { useLocation } from "hooks/useLocation";
import { useStorage } from "hooks/useStorage";
import { create, save } from "lib/firebase";
import { formatBytes, formatSeconds } from "lib/utils";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { MdAvTimer } from "react-icons/md";

export default function ContentUploadModal({}) {
  const { user } = useContext(AuthContext)
  const toast = useToast()

  const { upload } = useUpload({user})

  const [files, setFiles] = useState([])
  const [durations, setDurations] = useState({})
  const [progress, setProgress] = useState({})

  const progressListener = (val, name) => {
    setProgress({...progress, [name]: val})
  }

  const isUploading = () => {
    return Object.values(progress).filter(p => p < 100).length > 0
  }

  const uploadFiles = async () => {
    await Promise.all(files.map( async (file, i) => {
      const content = await create('content', {
        filename: file.name, size: file.size, clientId: user.clientId, duration: durations[file.name], type: file.name.match(/\.(jpeg|jpg|gif|png)$/) ? 'image': 'video'
      })
      const { url } = await upload(content, file, progressListener, i, files.length)

      content.url = url
      content.timestamp = moment().format()
      await save('content', content.id, content)
    }))
    toast({ title: 'Content uploaded!', status: 'success', duration: 5000, isClosable: true })
  }

  const prepare = async (files) => {
    setFiles(files)
    await Promise.all(files.map( async (file, i) => {
      const metadata = await getVideoDuration(file)
      setDurations({...metadata, [file.name]: metadata.duration})
    }))
  }

  const getVideoDuration = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const media = new Audio(reader.result);
        media.onloadedmetadata = () => resolve(media);
      };
      reader.readAsDataURL(file);
      reader.onerror = error => reject(error);
    })

  return (
    <>
      <DrawerBody>
        <Stack direction='column' spacing={4}>
          <FormControl>
            <FormLabel>Selected files</FormLabel>
            <Input type='file' py={4} height={16} multiple onChange={e => prepare(Array.from(e.target.files))} accept="image/*, video/*" />
          </FormControl>
          <List spacing={6}>
            <Stack direction='row' alignItems='center' gap='4'>
              <Text fontSize='sm' w='100%'>Filename</Text>
              <Text fontSize='sm' w='32'>Duration (s)</Text>
              <Text fontSize='sm' w='16'>Size</Text>
            </Stack>
            {files.map((file, index) => (
              <ListItem key={file.name}>
                <Stack>
                  <Stack direction='row' alignItems='center' gap='4'>
                    <Text fontSize='sm' w='100%' fontWeight='medium'>{file.name}</Text>
                    {file.type.includes('image')
                      ? <Input w='32' size='sm' type='number' placeholder="Set duration" onChange={e => setDurations({...durations, [file.name]: parseInt(e.target.value)})} />
                      : <Text fontSize='sm' w='32' >{formatSeconds(durations[file.name])}</Text>
                    }
                    <Text fontSize='sm' w='16' >{formatBytes(file.size)}</Text>
                  </Stack>
                  <Flex direction='row' alignItems='center'>
                    <Progress width='full' colorScheme='green' size='sm' value={progress[file.name] || 0} />
                  </Flex>
                </Stack>
              </ListItem>
            ))}
          </List>
        </Stack>
      </DrawerBody>
      <DrawerFooter>
        <Button colorScheme='brand' borderRadius="30px" w={120} me="10px" onClick={() => uploadFiles()} disabled={isUploading()}>
          {isUploading() ? <Spinner /> : 'Upload'}
        </Button>
      </DrawerFooter>
    </>
  );
}