import { ref, query, equalTo, orderByChild } from "firebase/database";
import { useEffect, useMemo, useState } from "react";
import { useList, useListVals } from "react-firebase-hooks/database";

import { database } from 'lib/firebase'
import { useGroup } from "./useGroup";
import { useBroadcast } from "./useBroadcast";
import { useDbQuery } from "./useDbQuery";
import { useDatabase } from "./useDatabase";

export const useLocation = ({user}) => {
  const { locations, broadcasts, groups  } = useDatabase({clientId: user?.clientId || 'loading'})
  const [items, loading, error, refresh] = locations

  // const [snapshots, loading, error, refresh] = useDbQuery(
  //   query(
  //     ref(database, 'location'), 
  //     orderByChild('clientId'), 
  //     equalTo(user?.clientId || 'loading')
  //   )
  // )

  // const [groups, loading2, error2, refresh2] = useGroup({user})
  // const [broadcasts, loading3, error3, refresh3] = useBroadcast({user})
  
  return [items.map(loc => {
    if (loc.broadcastId) loc.broadcast = broadcasts[0].find(br => br.id === loc.broadcastId)
    if (loc.groupId) loc.group = groups[0].find(gr => gr.id === loc.groupId)
    return loc
  }), loading, error, refresh];
};
