
// Chakra imports
import { Box, Button, Grid, GridItem, Modal, SimpleGrid, Spinner, Stack, useToast } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useContent } from "hooks/useContent";
import { AuthContext } from "contexts/AuthContext";
import ContentUploadModal from "views/modal/content-upload";
import { ActionDrawer } from "components/drawer";
import DataTableNew from "components/table/DataTableNew";
import { columnsContent } from "./columns/content";
import { tokenFilter } from "lib/utils";
import ContentGrid from "./ContentGrid";

export default function ContentPage() {
  const { user } = useContext(AuthContext);
  const [data, loading, error, refresh] = useContent({ user });
  
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <DataTableNew
        columns={columnsContent}
        data={data}
        loading={loading}
        refresh={refresh}
        search={(item, query) => tokenFilter(item.id + item.filename, query)}
        actions={(
          <ActionDrawer
            title='Upload content'
            size='lg'
            trigger={
              <Button colorScheme='brand' variant='solid' size='md' borderRadius={30} px='8'>
                Upload
              </Button>
            }
            >
            <ContentUploadModal />
          </ActionDrawer>
        )}
      />
    </Box>
  );
}
