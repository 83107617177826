import { ref, query, equalTo, orderByChild, getDatabase, get, onValue, forceLongPolling } from "firebase/database";
import { useEffect, useMemo, useRef, useState } from "react";
import { useList, useListVals } from "react-firebase-hooks/database";

import { database } from 'lib/firebase'
import { useGroup } from "./useGroup";
import { useBroadcast } from "./useBroadcast";

export const useDbQuery = (query) => {

  const cache = useRef({})

  const [state, setState] = useState({
    snapshots: null, error: null
  })

  const [loading, setLoading] = useState(true)

  const getVals = async (force) => {
    try {
      setLoading(true)
      const key = query._path.pieces_.join('/')
      if (force || !cache.current[key]) {
        const snapshots = await get(query)
        const vals = []
        snapshots.forEach((val) => {
          vals.push(val.val())
        })
        console.log('loaded', key)
        cache.current[key] = vals
        setState({ snapshots: vals, error: null })
      } else {
        setState({ snapshots: cache.current[key], error: null })
      }
      console.log(cache)
      setLoading(false)
    } catch (err) {
      console.trace(err)
      setState({ snapshots: [], loading: false, error: err.message })
      setLoading(false)
    }
  }

  useEffect(() => {
    if (query && !state.snapshots) {
      getVals(false)
    }
  }, [query, state.snapshots])

  return [
    state.snapshots || [],
    loading,
    state.error, 
    () => getVals(true)
  ]
};
