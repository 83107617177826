import React, { useContext } from 'react';
// Firebase auth functions
import {
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
} from 'firebase/auth';
// Firebase auth instance
import { auth } from 'lib/firebase';
import { useObject, useObjectVal } from 'react-firebase-hooks/database';
import { equalTo, orderByChild, query, ref } from 'firebase/database';
import { database } from 'lib/firebase';
import { AuthContext } from './AuthContext';
import { useDbQuery } from 'hooks/useDbQuery';
// Contexts
export const DataContext = React.createContext(null);

export const DataContextProvider = (props) => {

  const { user } = useContext(AuthContext)
  const [client, loading, error] = useObjectVal(ref(database, `user/${user?.uid}`));

  React.useEffect(() => {
    
  }, []);

  React.useEffect(() => {
    if (!user || !client) {
      return
    }
    if (client.clientId !== user.clientId) {
      // refresh all data
    }
  }, [user, client]);

  const [locations, locLoad, locErr, locRefresh] = useDbQuery(
    query(
      ref(database, 'location'), 
      orderByChild('clientId'), 
      equalTo(user?.clientId || 'loading')
    )
  )

  const [groups, grLoad, grErr, grRefresh] = useDbQuery(
    query(
      ref(database, 'group'), 
      orderByChild('clientId'), 
      equalTo(user?.clientId || 'loading')
    )
  )

  const [devices, dvLoad, dvErr, dvRefresh] = useDbQuery(
    query(
      ref(database, 'device'), 
      orderByChild('clientId'), 
      equalTo(user?.clientId || 'loading')
    )
  )

  const [broadcasts, brLoad, brErr, brRefresh] = useDbQuery(
    query(
      ref(database, 'broadcast'), 
      orderByChild('clientId'), 
      equalTo(user?.clientId || 'loading')
    )
  )

  const [schedules, schLoad, schErr, schRefresh] = useDbQuery(
    query(
      ref(database, 'scheduledBroadcastUpdate'), 
      orderByChild('clientId'), 
      equalTo(user?.clientId || 'loading')
    )
  )

  const [content, conLoad, conErr, conRefresh] = useDbQuery(
    query(
      ref(database, 'content'), 
      orderByChild('clientId'),
      equalTo(user?.clientId || 'loading')
    )
  )

  // Context provider
  return (
    <DataContext.Provider
      value={{
        client,
        user,
        locations: [locations, locLoad, locErr, locRefresh],
        groups: [groups, grLoad, grErr, grRefresh],
        devices: [devices, dvLoad, dvErr, dvRefresh],
        broadcasts: [broadcasts, brLoad, brErr, brRefresh],
        content: [content, conLoad, conErr, conRefresh],
        schedules: [schedules, schLoad, schErr, schRefresh],
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};
