import { ref, query, equalTo, orderByChild } from "firebase/database";
import { useEffect, useMemo, useState } from "react";
import { useList, useListVals } from "react-firebase-hooks/database";

import { database } from 'lib/firebase'
import { useGroup } from "./useGroup";
import { useBroadcast } from "./useBroadcast";
import { useDbQuery } from "./useDbQuery";

export const useDatabase = ({clientId}) => {
  const [locations, locLoad, locErr, locRefresh] = useDbQuery(
    query(
      ref(database, 'location'), 
      orderByChild('clientId'), 
      equalTo(clientId || 'loading')
    )
  )

  const [groups, grLoad, grErr, grRefresh] = useDbQuery(
    query(
      ref(database, 'group'), 
      orderByChild('clientId'), 
      equalTo(clientId || 'loading')
    )
  )

  const [devices, dvLoad, dvErr, dvRefresh] = useDbQuery(
    query(
      ref(database, 'device'), 
      orderByChild('clientId'), 
      equalTo(clientId || 'loading')
    )
  )

  const [broadcasts, brLoad, brErr, brRefresh] = useDbQuery(
    query(
      ref(database, 'broadcast'), 
      orderByChild('clientId'), 
      equalTo(clientId || 'loading')
    )
  )

  const [schedules, schLoad, schErr, schRefresh] = useDbQuery(
    query(
      ref(database, 'scheduledBroadcastUpdate'), 
      orderByChild('clientId'), 
      equalTo(clientId || 'loading')
    )
  )

  const [content, conLoad, conErr, conRefresh] = useDbQuery(
    query(
      ref(database, 'content'), 
      orderByChild('clientId'), 
      equalTo(clientId || 'loading')
    )
  )

  return {
    locations: [locations, locLoad, locErr, locRefresh],
    groups: [groups, grLoad, grErr, grRefresh],
    devices: [devices, dvLoad, dvErr, dvRefresh],
    broadcasts: [broadcasts, brLoad, brErr, brRefresh],
    content: [content, conLoad, conErr, conRefresh],
    schedules: [schedules, schLoad, schErr, schRefresh],
  }

};
