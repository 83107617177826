
// Chakra imports
import { Badge, Box, Button, Code, DrawerBody, DrawerFooter, Icon, Link, Select, SimpleGrid, Spinner, Stack, Tooltip, useDisclosure } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { useContent } from "hooks/useContent";
import { AuthContext } from "contexts/AuthContext";
import { useDevice } from "hooks/useDevice";
import { MdShareLocation } from "react-icons/md";
import { ActionDrawer } from "components/drawer";
import DeviceModal from "views/modal/device";
import { ConfirmationDialog } from "components/confirmation/confirmation";
import { remove } from "lib/firebase";
import LocationModal from "views/modal/location";
import DataTableNew from "components/table/DataTableNew";
import { columnsDevices } from "./columns/devices";
import { tokenFilter } from "lib/utils";

export default function DevicesPage() {
  const { user } = useContext(AuthContext);
  const [data, loading, error, refresh] = useDevice({ user });

  const [filter, setFilter] = useState({
    locationId: undefined
  })

  const locations = data.reduce((s, dv) => {
    const loc = dv.location || null
    if (!loc) return s
    if (s.find(o => o.id === loc.id)) return s
    s.push(loc)
    return s
  }, [])

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <DataTableNew
        loading={loading}
        columns={columnsDevices}
        data={data.filter(dv => {
          if (filter.locationId && dv.locationId !== filter.locationId) return false
          return true
        })}
        refresh={refresh}
        search={(item, query) => tokenFilter(item.id + item.code + item.location?.name + item.location?.address, query)}
        filters={(
          <Select placeholder='Any location'
              style={{borderRadius: '30px' }}
              width={200}
              value={filter.locationId}
              onChange={e => setFilter({ ...filter, locationId: e.target.value})}>
              {locations.map(loc => <option key={loc.id} value={loc.id}>{loc.name}</option>)}
            </Select>
        )}
        actions={(
          <ActionDrawer
            title='Create device'
            trigger={
              <Button colorScheme='brand' variant='solid' size='md' borderRadius={30} px='8'>
                New device
              </Button>
            }
            >
            <DeviceModal />
          </ActionDrawer>
        )}
      />
    </Box>
  );
}
