import { Button, Icon, Link, Stack, Text } from "@chakra-ui/react"
import { createColumnHelper } from "@tanstack/react-table"
import { ConfirmationDialog } from "components/confirmation/confirmation"
import { ActionDrawer } from "components/drawer"
import { defaultColumns } from "components/table/defaultColumns"
import { remove } from "lib/firebase"
import { formatBytes } from "lib/utils"
import { formatSeconds } from "lib/utils"
import VideoPlayer from "../components/video-player"
import moment from "moment"
const helper = createColumnHelper()

export const columnsContent = [
  helper.accessor('filename', {
    size: 700,
    cell: props => (
      <Text fontWeight='bold'>
        {props.getValue()}
      </Text>
    ),
  }),
  helper.accessor('duration', {
    size: 100,
    cell: props => (
      <Text>
        {/* {props.getValue() ? formatSeconds(props.getValue()) : 'N/A'} */}
        {props.getValue() ? Math.floor(props.getValue()) + 's' : 'N/A'}
      </Text>
    ),
  }),
  helper.accessor('size', {
    size: 100,
    cell: props => (
      <Text>
        {props.getValue() ? formatBytes(props.getValue()): 'N/A'}
      </Text>
    ),
  }),
  helper.accessor('timestamp', {
    size: 200,
    header: 'Uploaded',
    cell: props => (
      <Text>
        {props.getValue() ? moment(props.getValue()).format('LLL'): 'Long time ago...'}
      </Text>
    ),
  }),
  helper.display({
    id: 'actions',
    header: 'Actions',
    size: 150,
    cell: props => (
      <Stack direction='row' spacing={2} align='center'>
        <VideoPlayer 
          content={props.row.original}
          trigger={
            <Button colorScheme='brand' variant='ghost' size='xs'>
              View
            </Button>
          }
        />
        <ConfirmationDialog
          title='Delete content'
          message='Are you sure you want to delete this content?'
          confirmLabel='Delete'
          trigger={
            <Button colorScheme='red' variant='ghost' size='xs'>
              Delete
            </Button>
          }
          onConfirm={() => remove('content', props.row.original.id)}
          />
      </Stack>
    ),
  }),
]